import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import emailoutline from '@iconify/icons-eva/email-fill';
import emailOutline from '@iconify/icons-eva/email-outline';

import personaddfill from '@iconify/icons-eva/person-add-fill';
import textfill from '@iconify/icons-eva/text-fill';
import giftfill from '@iconify/icons-eva/gift-fill';
import messagesquarefill from '@iconify/icons-eva/message-square-fill';
import questionmarkcirclefill from '@iconify/icons-eva/question-mark-circle-fill';
import awardfill from '@iconify/icons-eva/award-fill';
import settingss from '@iconify/icons-eva/settings-2-fill';
import reglement from '@iconify/icons-eva/question-mark-circle-fill'
import categorie from '@iconify/icons-eva/plus-circle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [


  {
    title: 'Tableau de bord',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Historiques de connexion',
    path: 'Historique',
    icon: <Icon icon="material-symbols:manage-history" width="35" height="35" />
  },
  {
    title: 'Historiques de commandes',
    path: '/dashboard/facture',
    icon: <Icon icon="nimbus:money" width="35" height="35" />

  },
  {
    title: 'Inscrits',
    path: '/dashboard/inscrits',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Apprenants',
    path: '/dashboard/apprenants',
    icon: getIcon(peopleFill)
  },


  {
    title: 'Catégories',
    path: '/dashboard/categorie',
    icon: getIcon(categorie)
  },

  {
    title: 'Formateurs',
    path: '/dashboard/formateurs',
    icon: getIcon(personaddfill)
  },
  {
    title: 'Business',
    path: '/dashboard/Listbusiness',
    icon: getIcon(personaddfill)
  },
  {
    title: 'Type formations',
    path: '/dashboard/typeFormation',
    icon: getIcon(textfill)
  },
  {
    title: 'Formations',
    path: '/dashboard/formationAdmin',
    icon: getIcon(textfill)
  },
  // {
  //   title: 'Réservation Formation présentiel',
  //   path: '/dashboard/reservations',
  //   icon: getIcon(fileTextFill),
  // },

  {
    title: 'Logiciels',
    path: '/dashboard/licence',
    icon: getIcon(settingss)
  },
  // {
  //   title: 'Type Voucher',
  //   path: 'typeProd',
  //   icon: getIcon(textfill)
  // },
  {
    title: 'Nom propriétaire',
    path: '/dashboard/nomProp',
    icon: getIcon(categorie)
  },
  {
    title: 'Vouchers',
    path: 'voucher',
    icon: getIcon(giftfill)
  },

  {
    title: 'Partenaires',
    icon: getIcon(fileTextFill),
    children: [
      {
        title: 'Partenaires académiques',
        path: '/dashboard/partenaire',
        icon: getIcon(fileTextFill)
      },
      {
        title: 'Partenaires technologiques',
        path: '/dashboard/PartenaireTech',
        icon: getIcon(fileTextFill)
      },
      {
        title: 'Partenaires Industriels',
        path: '/dashboard/PartIndustriel',
        icon: getIcon(fileTextFill)
      },
      {
        title: 'Partenaires Institutionnels',
        path: '/dashboard/PartInstitutionnel',
        icon: getIcon(fileTextFill)
      },
    ]
  },

  {
    title: 'Scores Tests Blancs',
    path: '/dashboard/scoretest',
    icon: getIcon(questionmarkcirclefill)
  },
  {
    title: 'Liste des certifiés par formation',
    path: '/dashboard/scorecertifByForamtion',
    icon: getIcon(awardfill)
  },
  // {
  //   title: 'Liste des certifiés ',
  //   path: '/dashboard/scorecertif',
  //   icon: getIcon(awardfill)
  // },
  {
    title: 'Commentaire',
    path: '/dashboard/comments',
    icon: getIcon(messagesquarefill)
  },

  {
    title: 'Type reclamation',
    path: '/dashboard/typeRec',
    icon: getIcon(emailoutline)
  },
  {
    title: 'Publicité',
    path: '/dashboard/publicite',
    icon: getIcon(categorie)
  },
  {
    title: 'Contacts',
    path: '/dashboard/contact',
    icon: getIcon(emailoutline)
  },

  {
    title: 'Documents Juridiques',
    path: '/dashboard/reglement',
    icon: getIcon(reglement)
  },

  {
    title: 'Impots ',
    path: '/dashboard/impots',
    icon: getIcon(reglement)
  },

  {
    title: 'Envoyer Email',
    path: '/dashboard/sendEmail',
    icon: getIcon(emailoutline)
  },
  {
    title: 'Liste des emails',
    path: '/dashboard/emails',
    icon: getIcon(emailOutline)
  },

  {
    title: 'Chat apprenants',
    path: '/dashboard/chatApprenants',
    icon: getIcon(messagesquarefill)
  },
  
  // {
  //   title: 'Règlements Formateur',
  //   path: '/dashboard/reglementFormateur',
  //   icon: getIcon(reglement)
  // },

];


export default sidebarConfig;
