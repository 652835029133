import { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "@mui/material";
import Page from "../../components/Page";
import { CardBody, Row } from "reactstrap";
import ReactTable from "react-table-v6";
import { Stack, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import Cookies from 'js-cookie';

export default function User() {
  // Get the formateur ID from the URL parameters
  const { id } = useParams();

  const [formationsPrivee, setFormationsPrivee] = useState([]);
  const [formationsPublic, setFormationsPublic] = useState([]);
  const [apprenants, setApprenants] = useState([]);
  const [filteredFormations, setFilteredFormations] = useState([]);

  const token = Cookies.get("tokenAdmin");

  useEffect(() => {
    if (id) {
      getFormationsFormateur(id);
      getApprenants(id); // Pass the formateurId to getApprenants function

    }
  }, [id]);



  const getApprenants = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND_DEV}/getAllApprenantsListsBusiness/${id}`, // Include formateurId in the URL
        {
          headers: { Authorization: `Bearer ${token}`, withCredentials: true },
        }
      );

      const { listeInscrit } = res.data.data;
      const sortedApprenants = Array.isArray(listeInscrit)
        ? listeInscrit.sort((a, b) => new Date(a.dateInscrit) - new Date(b.dateInscrit))
        : [];

      setApprenants(sortedApprenants);
    } catch (error) {
      console.error('Error fetching apprenants:', error);
      setApprenants([]);
    }
  };
  console.log("apppp", apprenants)
  // Function to get the apprenants based on formateurId
  const getFormationsFormateur = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND_DEV}/getAllFormationsFormateurById/${id}`, // Include formateurId in the URL
        {
          headers: { Authorization: `Bearer ${token}`, withCredentials: true },
        }
      );
      console.log("app", res)

      setFormationsPrivee(res.data.formations);
    } catch (error) {
      console.error('Error fetching apprenants:', error);
    }
  };



  useEffect(() => {
    const getVouchersAndFormations = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URL_BACKEND_DEV}/getVoucherByBuisnessAffectAdminById/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          }
        );

        // Filter active vouchers
        const activeVouchers = res.data.result.filter(voucher => voucher.sent === true);
        console.log("active", activeVouchers);

        // Get distinct formations
        const distinctFormations = [
          ...new Map(activeVouchers.map(voucher => [voucher.formation._id, voucher.formation])).values(),
        ];
        console.log("distinctFormations", distinctFormations);

        // Update state
        setFormationsPublic(distinctFormations);
      } catch (error) {
        console.error('Error fetching vouchers and formations:', error);
      }
    };

    getVouchersAndFormations();
  }, [id, token]);

  // Add a useEffect to log the updated state
  useEffect(() => {
    console.log("Updated formationsPublic:", formationsPublic);
  }, [formationsPublic]);


  console.log("formationsPrivee", formationsPrivee)
  console.log("formationsPublic", formationsPublic)
  const allDistinctFormations = [
    ...new Map([...formationsPublic, ...formationsPrivee].map(formation => [formation._id, formation])).values(),
  ];

  console.log("All Distinct Formations", allDistinctFormations);


  useEffect(() => {
    const filterEnrolledByForConnectedUser = () => {
      if (!apprenants.length || !allDistinctFormations.length) return;

      // Get the IDs of connected user's students
      const apprenantIds = apprenants.map(apprenant => apprenant._id);

      // Filter enrolledBy for each formation
      const filteredFormations = allDistinctFormations.map(formation => {
        const filteredEnrolledBy = formation.enrolledBy.filter(enroll =>
          apprenantIds.includes(enroll.etudiantId.toString())
        );
        return {
          ...formation,
          enrolledBy: filteredEnrolledBy,
        };
      });
      setFilteredFormations(filteredFormations)

      console.log("Filtered Formations with Connected User's Students:", filteredFormations);

    };

    filterEnrolledByForConnectedUser();
  }, [allDistinctFormations]);


  const columns = [
    {
      Header: "Formation ",
      id: "titre",
      accessor: row => `${row.titre} `,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => (
        <span style={{ wordBreak: "break-all" }}>
{/* <Link
            to={{
              pathname: `/dashboard/Listbusiness/userbusiness/${original._id}`,
            }}
            state={{ formation: original }} // Pass the entire formation object
          > */}
            {original.titre}
          {/* </Link> */}
        </span>
      ),
    },
    {
      Header: "Formateur",
      accessor: "formateur",
      Cell: ({ original }) => <div>{original?.formateur.prenom} {original?.formateur.nom}</div>,
    },

    {
      Header: "Apprenants",
      accessor: "enrolledByPrivee", // This is only needed for the table's internal accessor; logic is dynamic below
      width: 100,
      Cell: ({ original }) => {
        const hasPrivateEnrollees = original?.status === "private" && original.enrolledByPrivee?.length > 0;
        const hasPublicEnrollees = original?.status === "public" && original.enrolledBy?.length > 0;

        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {hasPrivateEnrollees ? (
              <div>{original.enrolledByPrivee.length}</div>
            ) : hasPublicEnrollees ? (
              <div>{original.enrolledBy.length}</div>
            ) : (
              <div>Pas d'apprenants</div>
            )}
          </div>
        );
      },
    },

    // {
    //   Header: "Liste des certifiés",
    //   accessor: "formateur",
    //   Cell: ({ original }) => <div>{original?.formateur.prenom} {original?.formateur.nom}</div>,
    // },

  ];

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Formations
          </Typography>
        </Stack>

        <CardBody>
          <Row>
            <ReactTable
              data={filteredFormations}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
              defaultSorted={[{ id: "createdAt", desc: true }]}
            />
          </Row>
        </CardBody>
      </Container>
    </Page>
  );
}
