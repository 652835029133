import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentUserByToken } from './redux/authSlice';
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import DashboardFormateur from "./pages/DashboardFormateur"
import Blog from "./pages/Partenaires/Partenaire";
import User from "./pages/UserAdmin/User";
import PartenaireTech from "./pages/PartenairesTech/Partenaire";
import TypeProd from "./pages/TypeProd/TypeProd";
import NomProp from "./pages/NomProp/NomProp";
import FactureFormateur from "./pages/Facture/FactureFormateur";

import NotFound from "./pages/Page404";
import Profile from "./pages/Profile/Profile";
import { isAuth } from "./utils/AuthStorage";
import AddFormations from "./pages/Formations/AddFormations";
import Formations from "./pages/Formations/Formations";
import ScoresTestBlancs from "./pages/Formations/ScoresTestBlancs";
import ScoresCertif from "./pages/Formations/ScoresCertif";
import ScoreCourse from "./pages/Formations/ScoreCourse";
import DetailsFormations from "./pages/Formations/DetailsFormations";
import DetailsLicence from "./pages/Licences/DetailsLicence";

import Courses from "./pages/Course/Courses";
import AddCourse from "./pages/Course/AddCourse";
import UpdateCourse from "./pages/Course/UpdateCourse";
import AddQuiz from "./pages/Quiz/addQuiz";
import Contenu from "./pages/Formations/contenuformation"
import Voucher from "./pages/Vouchers/listVouchers";
import AddCertif from "./pages/Quizcertif/addQuiz";
import ScoreTest from "./pages/Quiz/listeStudentWithQuizz"
import ScoreCertif from "./pages/Quizcertif/Scorecertificat"
import Partner from "./pages/Partenaires/Partenaire"
import NouveauPartner from "./pages/Partenaires/NouveauPartner"
import DetailsPartner from "./pages/Partenaires/DetailsPartner";
import Formateurs from "./pages/Formateurs/formateur"
import AddFormateur from "./pages/Formateurs/AddFormateur"
import Comments from "./pages/comments/comments"
import Modules from "./pages/Modules/modules"
import AddModule from "./pages/Modules/AddModule"
import UpdateModule from "./pages/Modules/DetailsModule"
import QuizCourse from "./pages/Course/QuizCourse/AllQuestions";
import AddQuizCourse from "./pages/Course/QuizCourse/addQuiz"
import Contact from "./pages/contact/contacts"
import DetailsContact from "./pages/contact/DetailsContact"
import FormationAdmin from "./pages/Formations/FormationsAdmin"
import UserAdmin from "./pages/UserAdmin/UserAdmin"
import UserAdminApp from "./pages/UserAdmin/UserAdminApp"
import MdpOublie from "./pages/MdpOublie";
import VoucherFormateur from "./pages/Vouchers/listVouchersFormateurs";
import Publicite from "./pages/Publicite/Publicite";

import { useSelector } from "react-redux";
import { getCookie } from './utils/AuthStorage';
import UserFormateur from './pages/UserFormateur/utilisateur';
import Historique from "./pages/Historiques/testHistorique";
import AddLicence from "./pages/Licences/AddLicence"
import Licence from "./pages/Licences/licence"
import ContratV from "./pages/ContratVente/ContratVente";
import ConditionU from "./pages/ConditionUtilisation/ConditionUtilisation";
import DashboardBusiness from "./pages/DashboardBusiness"
import VoucherBusiness from "./pages/VouchersBusiness/listVouchersBusiness";
import VoucherByAdmin from "./pages/VouchersBusiness/VoucherByAdmin";
import SuccessPage from "./pages/VouchersBusiness/process";
import FactureBusiness from "./pages/VouchersBusiness/Facture";
import ContratVFormateur from "./pages/ContratVenteFormateur/ContratVente";
import ConditionUFormateur from "./pages/ConditionUtilisationFormateur/ConditionUtilisation";
import Categories from "./pages/Categorie/Categorie"
import DetailsCategorie from "./pages/Categorie/DetailsCategorie";
import AddCategorie from "./pages/Categorie/NouveauCategorie"
// ----------------------------------------------------------------------
import DetailsUser from "./pages/UserAdmin/DetailsUsers"
import PartInstitutionnel from "./pages/PartInstitutionnel/Partenaire"
import PartIndustriel from "./pages/PartIndustriel/Partenaire"
import TypeRec from "./pages/TypeRec/TypeRec";
import Facture from "./pages/Facture/Facture";
import UserAdminDesactiveApp from "./pages/UserAdmin/UserAdminDesactiveApp"
import UserAdminListemails from "./pages/UserAdmin/UserAdminListemails"
import ChatApprenants from "./pages/UserAdmin/chatApprenants"
import Reservations from "./pages/Formations/ReservationFormations"
import ReservationFormationsFormateur from "./pages/Formations/ReservationFormationsFormateur"
import TypeFormation from "./pages/TypeFormation/TypeFormation";
import ListeImpots from "./pages/Impots/listeImpots"
import Revenus from "./pages/Formations/RevenusFormateur";
import DetailsRevenus from "./pages/Formations/DetailsRevenus"
import Departements from "./pages/Departement/Departement"
import UserBusiness from "./pages/UserAdmin/UserBusiness"
import AllListBusiness from "./pages/UserAdmin/AllListBusiness"
import Business from "./pages/Business/business"
import AddBusiness from "./pages/Business/AddBusiness"
import Conference from "./pages/Conference/Conférence"
import AffectVoucher from "./pages/Vouchers/AffecterVoucherInscrits"
import UserBusinessFormation from "./pages/UserAdmin/UserBusinessFormation"
import DetailsUserBuisness from "./pages/UserAdmin/DetailsUserBusiness"
import UserAdminBusinessApp from "./pages/UserAdmin/UserAdminBusinessApp"
import FormationsAdminBusinessApp from "./pages/UserAdmin/FormationsAdminBusinessApp"
import InscritsAdminBusinessApp from "./pages/UserAdmin/InscritsAdminBusinessApp"

import ApprenantsAdminBusinessApp from "./pages/UserAdmin/ApprenantsAdminBusinessApp"
import CertifiesAdminBusinessApp from "./pages/UserAdmin/CertifiesAdminBusinessApp"
import FormationsBusiness from "./pages/Formations/FormationsBusiness"
import ScorecertificatBusiness from "./pages/Quizcertif/ScorecertificatBusiness"
import AllFormations from "./pages/Formations/AllFormations"
import ScoreCertifFormation from "./pages/Quizcertif/ScorecertificatParFormation"

export default function Router() {
  const [tokenn, setTokenn] = useState('');
  const token = getCookie('tokenAdmin');

  /// Slice
  const dispatch = useDispatch();
  useEffect(() => {

    if (token !== '' && token !== undefined) {
      dispatch(getCurrentUserByToken(token)).then((result) => {
        setTokenn(result.payload);
      });

    }
  }, [dispatch]);
  const userConnected = useSelector((state) => state.auth.CurrentUser);

  const role = userConnected?.role
  console.log("isAuth", isAuth())
  console.log("roleeeee", role)
  const isUndefined = () => {
    if (tokenn === null) {
      return false
    }
    else { return true }
  }
  return useRoutes([
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        {
          path: "login",
          element:
            isAuth() && role === "formateur" ? <Navigate to="/formateur/dashboard" /> :
              isAuth() && role === "admin" ? <Navigate to="/dashboard" /> :
                isAuth() && role === "business" ? <Navigate to="/business/dashboard" /> :
                  <Login />
        },
        {
          path: "dashboard",
          element:
            isAuth() && role === "formateur" ? <Navigate to="/formateur/dashboard" /> :
              isAuth() && role === "admin" ? <Navigate to="/dashboard" /> :
                isAuth() && role === "business" ? <Navigate to="/business/dashboard" /> :
                  ""
        },
        {
          path: "register",
          element:
            isAuth() ?
              role === "formateur" ? <Navigate to="/formateur/dashboard" /> :
                role === "business" ? <Navigate to="/business/dashboard" /> :
                  <Navigate to="/dashboard" /> :
              <Register />
        },

        { path: "recuperer_mdp", element: isAuth() ? <Navigate to="/formateur/dashboard" /> : <MdpOublie /> },
        { path: "404", element: <NotFound /> },
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },

    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "AddFormations", element: isAuth() ? <AddFormations /> : <Login />, },
        { path: "profile", element: isAuth() ? <Profile /> : <Login /> },
        { path: "detailFormation/:idFormation", element: isAuth() ? <DetailsFormations /> : <Login />, },
        { path: "updateCourse/:idCourse", element: isAuth() ? <UpdateCourse /> : <Login /> },
        { path: "addCourse/:idModule", element: isAuth() ? <AddCourse /> : <Login /> },
        { path: "courses/:idFormation", element: isAuth() ? <Courses /> : <Login /> },
        { path: "addquiz/:idFormation", element: isAuth() ? <AddQuiz /> : <Login /> },
        { path: "contenuformations/:idFormation", element: isAuth() ? <Contenu /> : <Login /> },
        { path: "addcertif/:idFormation", element: isAuth() ? <AddCertif /> : <Login /> },
        { path: "detailsUser/:idUser", element: isAuth() ? <DetailsUser /> : <Login /> },
        { path: "modules/:idFormation", element: isAuth() ? <Modules /> : <Login /> },
        { path: "addmodules/:idFormation", element: isAuth() ? <AddModule /> : <Login /> },
        { path: "updatemodules/:idModule", element: isAuth() ? <UpdateModule /> : <Login /> },
        { path: "quizcourse/:idModule", element: isAuth() ? <QuizCourse /> : <Login /> },
        { path: "addquizCourse/:idFormation", element: isAuth() ? <AddQuizCourse /> : <Login /> }
      ]
    },
    {
      path: "/dashboard",
      element:
        isAuth() && role === "formateur" ? <Navigate to="/formateur/dashboard" /> :
          isAuth() && role === "admin" ? <DashboardLayout/> :
            isAuth() && role === "business" ? <Navigate to="/business/dashboard" /> :
            !isAuth() ? <Login/> :
              "loading...",

      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "", element: isAuth() ? <DashboardApp /> : <Login /> },
        { path: "app", element: isAuth() ? <DashboardApp /> : <Login /> },
        { path: "user", element: isAuth() ? <User /> : <Login /> },
        { path: "utilisateurFormateur", element: isAuth() ? <UserFormateur /> : <Login /> },
        { path: "voucherFormateur", element: isAuth() ? <VoucherFormateur /> : <Login /> },
        { path: "inscrits", element: isAuth() ? <UserAdmin /> : <Login /> },
        { path: "apprenants", element: isAuth() ? <UserAdminApp /> : <Login /> },
        { path: "sendEmail", element: isAuth() ? <UserAdminDesactiveApp /> : <Login /> },
        { path: "Listbusiness", element: isAuth() ? <Business /> : <Login /> },
        { path: "addbusiness", element: isAuth() ? <AddBusiness /> : <Login /> },
        { path: "Listbusiness/userbusiness/:id", element: isAuth() ? <UserAdminBusinessApp /> : <Login /> },
        { path: "Listbusiness/formationsbusiness/:id", element: isAuth() ? <FormationsAdminBusinessApp /> : <Login /> },
        { path: "Listbusiness/apprenantsbusiness/:id", element: isAuth() ? <ApprenantsAdminBusinessApp /> : <Login /> },
        { path: "Listbusiness/inscritsbusiness/:id", element: isAuth() ? <InscritsAdminBusinessApp /> : <Login /> },
        { path: "Listbusiness/certifiesbusiness/:id", element: isAuth() ? <CertifiesAdminBusinessApp /> : <Login /> },

        { path: "publicite", element: isAuth() ? <Publicite /> : <Login /> },
        { path: "formations", element: isAuth() ? <Formations /> : <Login /> },
        { path: "ScoresTestBlancs/:idQuiz", element: isAuth() ? <ScoresTestBlancs /> : <Login /> },
        { path: "ScoresCertif/:idCertif", element: isAuth() ? <ScoresCertif /> : <Login /> },
        { path: "ScoreCourse/:idCourse", element: isAuth() ? <ScoreCourse /> : <Login /> },
        { path: "PartenaireTech", element: isAuth() ? <PartenaireTech /> : <Login /> },
        { path: "emails", element: isAuth() ? <UserAdminListemails /> : <Login /> },
        { path: "chatApprenants", element: isAuth() ? <ChatApprenants /> : <Login /> },

        { path: "conditions", element: isAuth() ? <ContratV /> : <Login /> },
        { path: "categorie", element: isAuth() ? <Categories /> : <Login /> },
        { path: "typeRec", element: isAuth() ? <TypeRec /> : <Login /> },
        { path: "typeProd", element: isAuth() ? <TypeProd /> : <Login /> },
        { path: "typeFormation", element: isAuth() ? <TypeFormation /> : <Login /> },
        { path: "nomProp", element: isAuth() ? <NomProp /> : <Login /> },
        { path: "reglement", element: isAuth() ? <ContratV /> : <Login /> },
        { path: "ConditionUtilisations", element: isAuth() ? <ConditionU /> : <Login /> },
        { path: "conditionsFormateur", element: isAuth() ? <ContratVFormateur /> : <Login /> },
        { path: "reglementFormateur", element: isAuth() ? <ConditionUFormateur /> : <Login /> },
        { path: "partenaire", element: isAuth() ? <Blog /> : <Login /> },
        { path: "PartInstitutionnel", element: isAuth() ? <PartInstitutionnel /> : <Login /> },
        { path: "PartIndustriel", element: isAuth() ? <PartIndustriel /> : <Login /> },
        { path: "formationAdmin", element: isAuth() ? <FormationAdmin /> : <Login /> },
        { path: "reservations", element: isAuth() ? <Reservations /> : <Login /> },
        { path: "profile", element: isAuth() ? <Profile /> : <Login /> },
        { path: "AddFormations", element: isAuth() ? <AddFormations /> : <Login />, },
        { path: "detailFormation/:idFormation", element: isAuth() ? <DetailsFormations /> : <Login />, },
        { path: "detailLicence/:idLicence", element: isAuth() ? <DetailsLicence /> : <Login />, },
        { path: "detailPartner/:idPartner", element: isAuth() ? <DetailsPartner /> : <Login />, },
        { path: "courses/:idFormation", element: isAuth() ? <Courses /> : <Login /> },
        { path: "voucher", element: isAuth() ? <Voucher /> : <Login /> },
        { path: "Historique", element: isAuth() ? <Historique /> : <Login /> },
        { path: "facture", element: isAuth() ? <Facture /> : <Login /> },
        { path: "addCourse/:idModule", element: isAuth() ? <AddCourse /> : <Login /> },
        { path: "updateCourse/:idCourse", element: isAuth() ? <UpdateCourse /> : <Login /> },
        { path: "addquiz/:idFormation", element: isAuth() ? <AddQuiz /> : <Login /> },
        { path: "contenuformations/:idFormation", element: isAuth() ? <Contenu /> : <Login /> },
        { path: "addcertif/:idFormation", element: isAuth() ? <AddCertif /> : <Login /> },
        { path: "scorecertifByForamtion", element: isAuth() ? <ScoreCertifFormation /> : <Login /> },

        { path: "scoretest", element: isAuth() ? <ScoreTest /> : <Login /> },
        { path: "scorecertif", element: isAuth() ? <ScoreCertif /> : <Login /> },
        { path: "partner", element: isAuth() ? <Partner /> : <Login /> },
        { path: "NouveauPartner", element: isAuth() ? <NouveauPartner /> : <Login /> },
        { path: "addLicence", element: isAuth() ? <AddLicence /> : <Login /> },
        { path: "licence", element: isAuth() ? <Licence /> : <Login /> },
        // { path: "detailsLicence", element: <DetailsLicence /> },
        { path: "formateurs", element: isAuth() ? <Formateurs /> : <Login /> },
        { path: "addformateur", element: isAuth() ? <AddFormateur /> : <Login /> },
        { path: "comments", element: isAuth() ? <Comments /> : <Login /> },


        { path: "modules/:idFormation", element: isAuth() ? <Modules /> : <Login /> },

        { path: "addmodules/:idFormation", element: isAuth() ? <AddModule /> : <Login /> },
        { path: "updatemodules/:idModule", element: isAuth() ? <UpdateModule /> : <Login /> },

        { path: "quizcourse/:idModule", element: isAuth() ? <QuizCourse /> : <Login /> },

        { path: "addquizCourse/:idFormation", element: isAuth() ? <AddQuizCourse /> : <Login /> },

        { path: "contact", element: isAuth() ? <Contact /> : <Login /> },
        { path: "detailscontact/:idContact", element: isAuth() ? <DetailsContact /> : <Login /> },

        { path: "addLicence", element: isAuth() ? <AddLicence /> : <Login /> },
        { path: "licence", element: isAuth() ? <Licence /> : <Login /> },
        { path: "impots", element: isAuth() ? <ListeImpots /> : <Login /> }


      ],
    },

    {
      path: "/formateur",
      
      element:
      isAuth() && role === "formateur" ? <DashboardLayout /> :
        isAuth() && role === "admin" ? <Navigate to="/dashboard"/> :
          isAuth() && role === "business" ? <Navigate to="/business/dashboard" /> :
          !isAuth() ? <Login/> :
            "loading...",
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "dashboard", element: isAuth() ? <DashboardFormateur /> : <Login /> },
        { path: "utilisateurFormateur", element: isAuth() ? <UserFormateur /> : <Login /> },
        { path: "voucherFormateur", element: isAuth() ? <VoucherFormateur /> : <Login /> },
        { path: "detailsUser/:idUser", element: isAuth() ? <DetailsUser /> : <Login /> },
        { path: "formations", element: isAuth() ? <Formations /> : <Login /> },
        { path: "reservations", element: isAuth() ? <ReservationFormationsFormateur /> : <Login /> },
        { path: "factureFormateur", element: isAuth() ? <FactureFormateur /> : <Login /> },
        { path: "revenusFormateur", element: isAuth() ? <Revenus /> : <Login /> },
        { path: "detailsRevenus", element: isAuth() ? <DetailsRevenus /> : <Login /> },
        { path: "conference/:id", element: isAuth() ? <Conference /> : <Login /> },



      ],
    },

    {
      path: "/business", 
      
      element:
      isAuth() && role === "formateur" ? <Navigate to="/formateur/dashboard" /> :
        isAuth() && role === "admin" ? <Navigate to="/dashboard"/> :
          isAuth() && role === "business" ? <DashboardLayout/> :
          !isAuth() ? <Login/> :
            "loading...",
    
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "dashboard", element: isAuth() ? <DashboardBusiness /> : <Login /> },
        { path: "voucherBusiness", element: isAuth() ? <VoucherBusiness /> : <Login /> },
        { path: "VoucherReduction", element: isAuth() ? <VoucherByAdmin /> : <Login /> },
        { path: "process", element: isAuth() ? <SuccessPage /> : <Login /> },
        { path: "formations", element: isAuth() ? <FormationsBusiness /> : <Login /> },
        { path: "listescertifies", element: isAuth() ? <ScorecertificatBusiness /> : <Login /> },
        { path: "Allformations", element: isAuth() ? <AllFormations /> : <Login /> },

        { path: "departement", element: isAuth() ? <Departements /> : <Login /> },
        { path: "conference/:id", element: isAuth() ? <Conference /> : <Login /> },
        { path: "affecterVoucher", element: isAuth() ? <AffectVoucher /> : <Login /> },



        { path: "Affectinscrits", element: isAuth() ? <UserBusiness /> : <Login /> },
        { path: "AffectinscritsFormation", element: isAuth() ? <UserBusinessFormation /> : <Login /> },
        { path: "Allinscrits", element: isAuth() ? <AllListBusiness /> : <Login /> },
        // { path: "departement", element: isAuth() ? <Departements /> : <Login /> },
        { path: "detailsUserBuisness/:idUser", element: isAuth() ? <DetailsUserBuisness /> : <Login /> },

      ],
    },



    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
