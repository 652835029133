import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
} from '@mui/material';
import { CardBody, Col, Row } from "reactstrap";
import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import ReactTable from 'react-table-v6'
import { Link } from "react-router-dom";
import "react-table-v6/react-table.css";

import Cookies from 'js-cookie';

export default function Formations() {

  const token = Cookies.get("tokenAdmin")
  const [listFormations, setListFormations] = useState([])
  const sanitizeRoomName = (name) => {
    if (typeof name === 'string') {
      // Remove special characters and limit the length
      return name.replace(/[^a-zA-Z0-9\s]/g, '').substring(0, 30);
    }
    return 'defaultRoomName';
  };


  useEffect(() => {

    getMoreDataa();
  }, []);
  const getMoreDataa = async () => {

    await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          withCredentials: true
        },
      })
      .then(response => {
        setListFormations(response.data.formations)
      })
      .catch(error => {
        console.error(error);
      });
  }
  const [certifs, setCertifs] = useState(0);
  const [apprenants, setApprenants] = useState([]);
  const [count, setCount] = useState();
  const [formations, setFormations] = useState([]);
  const [formationsPrivee, setFormationsPrivee] = useState([]); // Changed here to be an empty array
  const [lengthScores, setLengthScores] = useState(0); // State for counting scores >= 70

  const [filteredFormations, setFilteredFormations] = useState([]);

  useEffect(() => {
    const getVouchersAndFormations = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URL_BACKEND_DEV}/getVoucherByBuisnessAffectAdmin`,
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          }
        );
        const activeVouchers = res.data.result.filter(voucher => voucher.sent === true);

        const distinctFormations = [

          ...new Map(activeVouchers.map(voucher => [voucher.formation._id, voucher.formation])).values(),
           
        ];

        setFormations(distinctFormations);
      } catch (error) {
        console.error('Error fetching vouchers and formations:', error);
      }
    };

    getVouchersAndFormations();
  }, [token]);

  useEffect(() => {
    getMoreData();
  }, []);

  const getMoreData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`,
        {
          headers: { Authorization: `Bearer ${token}`, withCredentials: true },
        }
      );
      setFormationsPrivee(response.data.formations || []);  // Set to an empty array if undefined
    } catch (error) {
      console.error(error);
    }
  };

  const allDistinctFormations = [
    ...new Map([...formations, ...formationsPrivee].map(formation => [formation._id, formation])).values(),
  ];

  console.log("All Distinct Formations", allDistinctFormations);

  useEffect(() => {
    const filterEnrolledByForConnectedUser = () => {
      if (!apprenants.length || !allDistinctFormations.length) return;

      // Get the IDs of connected user's students
      const apprenantIds = apprenants.map(apprenant => apprenant._id);

      // Filter enrolledBy for each formation
      const filteredFormations = allDistinctFormations.map(formation => {
        const filteredEnrolledBy = formation.enrolledBy.filter(enroll =>
          apprenantIds.includes(enroll.etudiantId.toString())
        );
        return {
          ...formation,
          enrolledBy: filteredEnrolledBy,
        };
      });
      setFilteredFormations(filteredFormations)

      console.log("Filtered Formations with Connected User's Students:", filteredFormations);

      // Optionally, set the filtered formations to state if needed
      // setFilteredFormations(filteredFormations);
    };

    filterEnrolledByForConnectedUser();
  }, [allDistinctFormations, apprenants]);



  useEffect(() => {
    getApprenants();
  }, []);
  console.log(apprenants)
  const getApprenants = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND_DEV}/getAllApprenantsListss`, {
        headers: { Authorization: `Bearer ${token}`, withCredentials: true },
      });

      const listeInscrit = res.data.data.listeInscrit;
      const sortedApprenants = Array.isArray(listeInscrit)
        ? listeInscrit.sort((a, b) => new Date(a.dateInscrit) - new Date(b.dateInscrit))
        : [];

      setApprenants(sortedApprenants);
      const count = sortedApprenants.filter(apprenant =>
        (apprenant.mesCertificats && apprenant.mesCertificats.length > 0)
      ).length;
      setCount(count);
      console.log(`Number of users with enrolledPriv or enrolled: ${count}`);
    } catch (error) {
      console.error('Error fetching all apprenants:', error);
      setApprenants([]);
    }
  };



  const [scores, setScores] = useState([]);
  const [filteredScores, setFilteredScores] = useState([]);

  useEffect(() => {


    // Fetch scores
    const fetchScores = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND_DEV}/scorecertif`, {
          headers: { Authorization: `Bearer ${token}`, withCredentials: true },
        });
        console.log(response)
        setScores(response.data.data); // Assuming this contains the array of score objects
      } catch (error) {
        console.error('Error fetching scores:', error);
      }
    };

    getApprenants();
    fetchScores();
  }, [apprenants]);
  console.log(scores)
  useEffect(() => {
    // Filter scores based on apprenants
    if (apprenants.length > 0 && scores.length > 0) {
      const filtered = scores.filter(score =>
        apprenants.some(apprenant => apprenant?._id === score.etudiant?._id) &&
        allDistinctFormations.some(formation => formation?._id === score?.formation)
      );
      setFilteredScores(filtered);

      const countScoresAbove70 = filtered.filter(score => parseFloat(score.score) >= 70).length;
      setLengthScores(countScoresAbove70);
    }
  }, [apprenants]);

  console.log(lengthScores)


  const columns = [
    {
      Header: "Titre formation",
      accessor: "titre",

      filterMethod: (filter, row) =>
        row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            <Link to={"/contenuformations/" + original._id}>

              {original.titre}
            </Link>
          </span>
        );
      },
    },


    {
      Header: "Propriétaire",
      accessor: "typeFormation",
      filterMethod: (filter, row) => {
        const TypeFormatinName = row[filter.id]?.titre || ""; // Access the "nom" property of the "categorie" object
        return TypeFormatinName.toLowerCase().includes(filter.value.toLowerCase());
      },
      Cell: ({ original }) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
          {original?.status === "private" ? (
            <div>Business</div>
          ) : (
            <div>Fastuz</div>
          )}
        </div>
      },
    },


    {
      Header: "Apprenants",
      accessor: "enrolledByPrivee", // This is only needed for the table's internal accessor; logic is dynamic below
      Cell: ({ original }) => {
        const hasPrivateEnrollees = original?.status === "private" && original.enrolledByPrivee?.length > 0;
        const hasPublicEnrollees = original?.status === "public" && original.enrolledBy?.length > 0;
{console.log()}
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {hasPrivateEnrollees ? (
              <div>{original.enrolledByPrivee.length}</div>
            ) : hasPublicEnrollees ? (
              <div>{original.enrolledBy.length}</div>
            ) : (
              <div>Pas d'apprenants</div>
            )}
          </div>
        );
      },
    }, 
    {
      Header: "status",
      accessor: "status", // This is only needed for the table's internal accessor; logic is dynamic below
      width: 100,
      Cell: ({ original }) => {
        const hasPrivateEnrollees = original?.status === "private" && original.enrolledByPrivee?.length > 0;
        const hasPublicEnrollees = original?.status === "public" && original.enrolledBy?.length > 0;
{console.log()}
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* {hasPrivateEnrollees ? (
              <div>{original.enrolledByPrivee.length}</div>
            ) : hasPublicEnrollees ? (
              <div>{original.enrolledBy.length}</div>
            ) : (
              <div>Pas d'apprenants</div>
            )} */}
            {original.status}
          </div>
        );
      },
    }



  ];

  const navigate = useNavigate();

  console.log("formations", formations)
  console.log("distinct", allDistinctFormations)

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Toutes les Formations
          </Typography>

        </Stack>

        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  data={filteredFormations}
                  nextText={"Suivant"}
                  previousText={"Précedent"}
                  rowsText={"Lignes"}
                  columns={columns}
                  defaultPageSize={5}
                  filterable={true}
                  noDataText="No data is available"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </Page>

  );
}





// import plusFill from '@iconify/icons-eva/plus-fill';
// import {
//   Card,
//   Stack,
//   Button,
//   Container,
//   Typography,
// } from '@mui/material';
// import { CardBody, Col, Row } from "reactstrap";
// import Page from '../../components/Page';
// import { useNavigate } from 'react-router-dom';
// import axios from "axios";
// import { useEffect, useState } from "react";
// import ReactTable from 'react-table-v6'
// import { Link } from "react-router-dom";
// import "react-table-v6/react-table.css";
// import Swal from "sweetalert2";
// import { Icon } from '@iconify/react';
// import { SupprimerFormationAPI } from '../../utils/api';
// import { LoadingButton } from '@mui/lab';
// import Cookies from 'js-cookie';
// import moment from "moment";

// export default function Formations() {

//   const token = Cookies.get("tokenAdmin")
//   const [listFormations, setListFormations] = useState([])

//   const sanitizeRoomName = (name) => {
//     if (typeof name === 'string') {
//       // Remove special characters and limit the length
//       return name.replace(/[^a-zA-Z0-9\s]/g, '').substring(0, 30);
//     }
//     return 'defaultRoomName';
//   };

//   useEffect(() => {

//     getMoreData();
//   }, []);
//   const getMoreData = async () => {

//     await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           withCredentials: true
//         },
//       })
//       .then(response => {
//         setListFormations(response.data.formations)
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   }

//   const generateOptions = (formations) => {
//     // Get unique typeFormation values
//     const uniqueTypeFormations = [...new Set(formations.map(item => item.typeFormation))];

//     // Generate options based on unique typeFormation values
//     const options = uniqueTypeFormations.map((typeFormation) => (
//       <option key={typeFormation} value={typeFormation}>
//         {typeFormation}
//       </option>
//     ));

//     return options;
//   };

//   const columns = [
//     {
//       Header: "Titre formation",
//       accessor: "titre",

//       filterMethod: (filter, row) =>
//         row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
//       Cell: ({ original }) => {
//         return (
//           <span style={{ wordBreak: "break-all" }}>
//             <Link to={"/contenuformations/" + original._id}>

//               {original.titre}
//             </Link>
//           </span>
//         );
//       },
//     },
//     {
//       Header: "Date de création",
//       accessor: "createdAt",
//       Cell: ({ value }) => {
//         return (
//           <span style={{ wordBreak: "break-all" }}>
//             {moment(value).locale("fr").format("DD/MM/YYYY")}
//           </span>
//         );
//       },
//       filterMethod: (filter, row) => {
//         const date = moment(row[filter.id]).format("DD/MM/YYYY");
//         return date.includes(filter.value);
//       },
//     },
//     {
//       Header: "Lien conférence",
//       width: 150,
//       accessor: "urlMeet",
//       Cell: ({ value, row }) => {
//         const original = row.original;

//         return (
//           <div style={{ display: "flex", justifyContent: "center" }}>
//             <a
//               href={`https://8x8.vc/vpaas-magic-cookie-e9c561d3141d4046b681f68d02c3c08d/${sanitizeRoomName(row?.titre)}`}
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               Lien de la conférence
//             </a>


//           </div>
//         );
//       }
//     },


//     {
//       Header: "Apprenants",
//       accessor: "enrolledBy",
//       Cell: ({ original }) => {
//         return <div style={{ display: "flex", justifyContent: "center" }}>
//           {original.listeInscritBuisness.length > 0 ? (
//             <div>{original?.listeInscritBuisness.length}</div>
//           ) : (
//             <div>Pas d'apprenants</div>
//           )}
//         </div>
//       },
//     },
//     {
//       Header: "Actions",
//       filterable: false,
//       sortable: false,
//       Cell: (row) => {
//         return (
//           <>
//             <div className="d-block w-100 text-center">
//               <Link to={"/detailFormation/" + row.original._id}>
//                 <LoadingButton
//                 >
//                   Details Formations
//                 </LoadingButton>
//               </Link>
//             </div>

//             <LoadingButton

//               onClick={async () => {

//                 Swal.fire({
//                   title: "Êtes-vous sûr de vouloir supprimer cette formation?",
//                   icon: "warning",
//                   showCancelButton: true,
//                   cancelButtonText: "Annuler",

//                   confirmButtonColor: "#0d3077",
//                   cancelButtonColor: "#d33",
//                   confirmButtonText: "Supprimer formation!",
//                 }).then(async (result) => {
//                   if (result.isConfirmed) {
//                     await SupprimerFormationAPI(row.original._id);

//                     await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`,
//                       {
//                         headers: {
//                           Authorization: `Bearer ${token}`,
//                           withCredentials: true
//                         },
//                       }).then(async (res) => {
//                         if (res.status === 200) {

//                           Swal.fire("Formation supprimé!");

//                         } else {
//                           Swal.fire(
//                             "Formation supprimé!"
//                           );
//                         }
//                       });
//                   }
//                 });




//               }}
//             >
//               Supprimer
//             </LoadingButton>

//           </>
//         );
//       },
//     },

//   ];

//   const navigate = useNavigate();



//   return (
//     <Page title="Dashboard | Fastuz">
//       <Container>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//           <Typography variant="h4" gutterBottom>
//             Formations
//           </Typography>
//           <Button
//             variant="contained"
//             onClick={() => navigate("/AddFormations")}
//             startIcon={<Icon icon={plusFill} />}
//           >
//             Ajouter Formation
//           </Button>
//         </Stack>

//         <Row>
//           <Col md="12">
//             <Card className="main-card mb-3">
//               <CardBody>
//                 <ReactTable
//                   data={listFormations}
//                   nextText={"Suivant"}
//                   previousText={"Précedent"}
//                   rowsText={"Lignes"}
//                   columns={columns}
//                   defaultPageSize={5}
//                   filterable={true}
//                   noDataText="No data is available"
//                 />
//               </CardBody>
//             </Card>
//           </Col>
//         </Row>
//       </Container>

//     </Page>

//   );
// }

