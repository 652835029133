import moment from "moment";
import {
  Stack,
  Container,
  Typography,
} from '@mui/material';
import Page from '../../components/Page';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import ReactTable from "react-table-v6";
import { useDispatch } from "react-redux";
import Cookies from 'js-cookie';


const CertifiedLearners = () => {
  const [scores, setScores] = useState([]);
  const [filteredScores, setFilteredScores] = useState([]);
  const [apprenants, setApprenants] = useState([]);
  const [formations, setFormations] = useState([]);
  const [formationsPrivee, setFormationsPrivee] = useState([]);
  const dispatch = useDispatch();
  const [filteredFormations, setFilteredFormations] = useState([]);

  const token = Cookies.get("tokenAdmin")

  // Fetch apprenants
  useEffect(() => {
    const fetchApprenants = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND_DEV}/getAllApprenantsListss`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log("reeees", res.data)
        setApprenants(res.data.data.listeInscrit || []);
      } catch (error) {
        console.error("Error fetching apprenants:", error);
      }
    };
    fetchApprenants();
  }, [token]);

  // Fetch formations
  useEffect(() => {
    const getVouchersAndFormations = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_URL_BACKEND_DEV}/getVoucherByBuisnessAffectAdmin`,
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          }
        );
        const activeVouchers = res.data.result.filter(voucher => voucher.expired === true);

        const distinctFormations = [

          ...new Map(activeVouchers.map(voucher => [voucher.formation._id, voucher.formation])).values(),

        ];

        setFormations(distinctFormations);
      } catch (error) {
        console.error('Error fetching vouchers and formations:', error);
      }
    };

    getVouchersAndFormations();
  }, [token]);

  useEffect(() => {
    getMoreData();
  }, []);

  const getMoreData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/getAllFormationsFormateur`,
        {
          headers: { Authorization: `Bearer ${token}`, withCredentials: true },
        }
      );
      setFormationsPrivee(response.data.formations || []);  // Set to an empty array if undefined
    } catch (error) {
      console.error(error);
    }
  };

  const allDistinctFormations = [
    ...new Map([...formations, ...formationsPrivee].map(formation => [formation._id, formation])).values(),
  ];
  console.log("alldistinctformations", allDistinctFormations)
  console.log("formationsPrivee", formationsPrivee)
  console.log("formations", formations)

  console.log("filteredFormations", filteredFormations)
  console.log("apprenants", apprenants)
  // Fetch scores
  // Fetch scores with filtering for >=80
  useEffect(() => {
    const fetchScores = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URL_BACKEND_DEV}/scorecertif`,
          {}, // Pass empty body if no additional data is needed
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // Filter scores where score >= 80
        console.log("fidlfkjkjdlde", res.data.data)

        const filteredScores = res.data.data.filter(score => parseFloat(score.score) >= 80);
        setScores(filteredScores);
      } catch (error) {
        console.error("Error fetching scores:", error);
      }
    };
    if (token) {
      fetchScores();
    }
  }, [token]);
  const apprenantIds = apprenants.map(apprenant => apprenant._id);
  const formationIds = allDistinctFormations.map(formation => formation._id);
  console.log("apprenantIds", apprenantIds)
  console.log("formationIds", formationIds)
  // Combine scores, apprenants, and formations
  useEffect(() => {
    if (apprenants.length && allDistinctFormations.length && scores.length) {
      // Map apprenants and formations IDs for quick lookup
      const apprenantIds = apprenants.map(apprenant => apprenant._id);
      const formationIds = allDistinctFormations.map(formation => formation._id);
      console.log("apprenantIds", apprenantIds)
      console.log("formationIds", formationIds)
      // Filter scores where etudiant and formation match
      const filtered = scores.filter(
        score =>
          apprenantIds.includes(score.etudiant._id) &&
          formationIds.includes(score.formation)
      );

      setFilteredScores(filtered);
    }
  }, [scores]);

  console.log("scores", scores)
  console.log("filteredscores", filteredScores)

  const columns = [
    {
      Header: "Apprenants",
      id: "nomPrenom",
      accessor: row => `${row.etudiant?.nom || ""} ${row.etudiant?.prenom || ""}`,
      Cell: ({ original }) => (
        <div>
          {original.etudiant?.nom} {original.etudiant?.prenom}
        </div>
      ),
    },
    {
      Header: "Formations",
      accessor: "formation",
      Cell: ({ original }) => {
        return <div>{original.certiff?.formation_id?.titre}</div>;
      },
    },
    {
      Header: "Propriétaire",
      accessor: "formation",
      filterMethod: (filter, row) => {
        const TypeFormatinName = row[filter.id]?.titre || ""; // Access the "nom" property of the "categorie" object
        return TypeFormatinName.toLowerCase().includes(filter.value.toLowerCase());
      },
      Cell: ({ original }) => {
        return <div style={{ display: "flex", justifyContent: "center" }}>
          {original.certiff?.formation_id?.status === "private" ? (
            <div>Business</div>
          ) : (
            <div>Fastuz</div>
          )}
        </div>
      },
    },
    {
      Header: "Scores",
      accessor: "score",
      Cell: ({ original }) => (
        <div>{parseFloat(original.score).toFixed(2)}%</div>
      ),
    },
    {
      Header: "Date de passage du test",
      accessor: "createdAt",
      Cell: ({ original }) => (
        <span>{moment(original.createdAt).format("DD/MM/YYYY : HH:mm")}</span>
      ),
    },
  ];


  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Apprenants certifiés
          </Typography>
        </Stack>

        <ReactTable
              data={filteredScores}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"

            />


      </Container>
    </Page>
  );
};

export default CertifiedLearners;
