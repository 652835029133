// import React, { useEffect, useState } from 'react'; 
// import { Container, Stack, Typography, MenuItem, FormControl, Select, InputLabel, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
// import Page from '../../components/Page';
// import axios from 'axios';
// import ReactTable from 'react-table-v6';
// import "react-table-v6/react-table.css";

// export default function Formations() {
//   const [formationScores, setFormationScores] = useState([]);
//   const [formationTitles, setFormationTitles] = useState([]); // Store the list of formation titles
//   const [selectedTitle, setSelectedTitle] = useState(""); // Store the selected formation title
//   const [selectedStudents, setSelectedStudents] = useState([]); // Store students for selected formation
//   const [dialogOpen, setDialogOpen] = useState(false); // Control dialog visibility

//   // Fetch the formation scores and the distinct formation titles
//   useEffect(() => {
//     const getData = async () => {
//       try {
//         const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/scorecertifByFormation`);
//         setFormationScores(res.data.data); // Assuming the response contains the aggregated data

//         // Extract distinct formation titles for the dropdown filter
//         const titles = res.data.data.map(item => item.formation.titre);
//         setFormationTitles([...new Set(titles)]); // Remove duplicates
//       } catch (err) {
//         console.error("Error fetching data", err);
//       }
//     };
//     getData();
//   }, []);

//   // Filter logic for formation title
//   const filteredData = selectedTitle
//     ? formationScores.filter(item => item.formation.titre.toLowerCase().includes(selectedTitle.toLowerCase()))
//     : formationScores;

//   const columns = [
//     {
//       Header: "Formation",
//       accessor: "formation.titre",
//       Cell: ({ original }) => (
//         <div style={{ whiteSpace: "pre-wrap" }}>{original?.formation?.titre}</div>
//       ),
//     },
//     {
//       Header: "Nombres des certifiées",
//       accessor: "numberOfStudents",
//       Cell: ({ original }) => (
//         <Button
//           variant="text"
//           color="primary"
//           onClick={() => {
//             setSelectedStudents(original.studentDetails); // Set selected students
//             setDialogOpen(true); // Open dialog
//           }}
//         >

//           {original.numberOfStudents} 
//         </Button>
//       ),
//     },
//   ];

//   return (
//     <Page title="Dashboard | Fastuz">
//       <Container>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//           <Typography variant="h4" gutterBottom>
//             Apprenants certifiés par formation
//           </Typography>

//           {/* Dropdown Filter for Formation Title */}
//           <FormControl variant="outlined" size="small" >
//             <InputLabel shrink>Formation</InputLabel> {/* Apply shrink to fix label position */}
//             <Select
//               value={selectedTitle}
//               onChange={(e) => setSelectedTitle(e.target.value)}
//               label="Formation"
//               displayEmpty
//             >
//               <MenuItem value="">
//                 <em>Toutes les formations</em>
//               </MenuItem>
//               {formationTitles.map((title, index) => (
//                 <MenuItem key={index} value={title}>
//                   {title}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Stack>

//         <ReactTable
//           data={filteredData}
//           nextText={"Suivant"}
//           previousText={"Précédent"}
//           rowsText={"Lignes"}
//           columns={columns}
//           defaultPageSize={10}
//           filterable={true}
//           noDataText="Aucune donnée disponible"
//         />

//         {/* Dialog for Student List */}
//         <Dialog
//           open={dialogOpen}
//           onClose={() => setDialogOpen(false)}
//           maxWidth="200"
//           fullWidth
//         >
//           <DialogTitle>Liste des apprenants certifiés</DialogTitle>
//           <DialogContent>
//           <ReactTable
//                     filterable={true}

//   data={selectedStudents || []}
//   columns={[
//     {
//       Header: "Nom",
//       accessor: "nom",
//       filterMethod: (filter, row) =>
//         row.nom.toLowerCase().includes(filter.value.toLowerCase()), // Adjust to your field in the `etudiants` collection
//     },
//     {
//       Header: "Prenom",
//       accessor: "prenom", 
//       filterMethod: (filter, row) =>
//         row.prenom.toLowerCase().includes(filter.value.toLowerCase()),// Adjust to your field in the `etudiants` collection
//     },

//     {
//       Header: "Email",
//       accessor: "email",

//        // Adjust to your field in the `etudiants` collection
//     },
//     {
//       Header: "Score",
//       accessor: "score",
//       Cell: ({ value }) => {
//         if (value || value === 0) {
//           return `${value.toFixed(2)}%`; // Format the score and add the percentage symbol
//         }
//         return "N/A"; // If no score, display "N/A"
//       }
//     },

//     {
//       Header: "Date",
//       accessor: "createdAt",
//       Cell: ({ value }) => {
//         if (value) {
//           const date = new Date(value); // Convert the value (ISO string) to a Date object

//           // Manually format the date as DD/MM/YYYY
//           const day = String(date.getDate()).padStart(2, '0'); // Ensure day is 2 digits
//           const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based, so add 1)
//           const year = date.getFullYear(); // Get the full year

//           return `${day}/${month}/${year}`; // Return formatted date as DD/MM/YYYY
//         }
//         return "N/A"; // If no date value, display "N/A"
//       }
//     }


//   ]}
//   defaultPageSize={5} // Adjust based on your needs
//   className="-striped -highlight"
//   nextText="Suivant"
//   previousText="Précédent"
//   rowsText="Lignes"
//   pageText="Page"
//   ofText="de"
//   noDataText="Aucune donnée disponible"
// />


//           </DialogContent>
//         </Dialog>
//       </Container>
//     </Page>
//   );
// }

import React, { useEffect, useState } from 'react';
import { Container, Stack, Typography, MenuItem, FormControl, Select, InputLabel, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import Page from '../../components/Page';
import axios from 'axios';
import ReactTable from 'react-table-v6';
import "react-table-v6/react-table.css";

export default function Formations() {
  const [formationScores, setFormationScores] = useState([]);
  const [formationTitles, setFormationTitles] = useState([]); // Store the list of formation titles
  const [selectedTitle, setSelectedTitle] = useState(""); // Store the selected formation title
  const [selectedStudents, setSelectedStudents] = useState([]); // Store students for selected formation
  const [selectedFormationName, setSelectedFormationName] = useState(""); // Store the name of the selected formation
  const [dialogOpen, setDialogOpen] = useState(false); // Control dialog visibility

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_URL_BACKEND_DEV + `/scorecertifByFormation`);
        setFormationScores(res.data.data); // Assuming the response contains the aggregated data

        // Extract distinct formation titles for the dropdown filter
        const titles = res.data.data.map(item => item.formation.titre);
        setFormationTitles([...new Set(titles)]); // Remove duplicates
      } catch (err) {
        console.error("Error fetching data", err);
      }
    };
    getData();
  }, []);

  // Filter logic for formation title
  const filteredData = selectedTitle
    ? formationScores.filter(item => item.formation.titre.toLowerCase().includes(selectedTitle.toLowerCase()))
    : formationScores;
  const columns = [
    {
      Header: "Formation",
      accessor: "formation.titre",
      Cell: ({ original }) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{original?.formation?.titre}</div>
      ),
    },
    {
      Header: "Nombres des certifiées",
      accessor: "numberOfStudents",
      Cell: ({ original }) => (
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            setSelectedStudents(original.studentDetails); // Set selected students
            setSelectedFormationName(original.formation.titre); // Set the name of the selected formation
            setDialogOpen(true); // Open dialog
          }}
        >

          {original.numberOfStudents}
        </Button>
      ),
    },
  ];

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Apprenants certifiés par formation
          </Typography>

          {/* Dropdown Filter for Formation Title */}
          <FormControl variant="outlined" size="small" >
            <InputLabel shrink>Formation</InputLabel> {/* Apply shrink to fix label position */}
            <Select
              value={selectedTitle}
              onChange={(e) => setSelectedTitle(e.target.value)}
              label="Formation"
              displayEmpty
            >
              <MenuItem value="">
                <em>Toutes les formations</em>
              </MenuItem>
              {formationTitles.map((title, index) => (
                <MenuItem key={index} value={title}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <ReactTable
          data={filteredData}
          nextText={"Suivant"}
          previousText={"Précédent"}
          rowsText={"Lignes"}
          columns={columns}
          defaultPageSize={10}
          filterable={true}
          noDataText="Aucune donnée disponible"
        />

        {/* Dialog for Student List */}
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          maxWidth="200"
          fullWidth
        >
          <DialogTitle>Liste des apprenants certifiés - {selectedFormationName}</DialogTitle>
          <DialogContent>
            <ReactTable
              filterable={true}

              data={selectedStudents || []}
              columns={[
                {
                  Header: "Nom",
                  accessor: "nom",
                  filterMethod: (filter, row) =>
                    row.nom.toLowerCase().includes(filter.value.toLowerCase()), // Adjust to your field in the `etudiants` collection
                },
                {
                  Header: "Prenom",
                  accessor: "prenom",
                  filterMethod: (filter, row) =>
                    row.prenom.toLowerCase().includes(filter.value.toLowerCase()),// Adjust to your field in the `etudiants` collection
                },

                {
                  Header: "Email",
                  accessor: "email",

                  // Adjust to your field in the `etudiants` collection
                },
                {
                  Header: "Score",
                  accessor: "score",
                  Cell: ({ value }) => {
                    if (value || value === 0) {
                      return `${value.toFixed(2)}%`; // Format the score and add the percentage symbol
                    }
                    return "N/A"; // If no score, display "N/A"
                  }
                },
                {
                  Header: "Date",
                  accessor: "dateAchieved", // Use the correct field from the enriched API response
                  Cell: ({ value }) => {
                    if (value) {
                      const date = new Date(value);
                      const day = String(date.getDate()).padStart(2, '0');
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const year = date.getFullYear();
                      return `${day}/${month}/${year}`;
                    }
                    return "N/A";
                  }
                }
                // {
                //   Header: "Date",
                //   accessor: "createdAt",
                //   Cell: ({ value }) => {
                //     if (value) {
                //       const date = new Date(value); // Convert the value (ISO string) to a Date object

                //       // Manually format the date as DD/MM/YYYY
                //       const day = String(date.getDate()).padStart(2, '0'); // Ensure day is 2 digits
                //       const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based, so add 1)
                //       const year = date.getFullYear(); // Get the full year

                //       return `${day}/${month}/${year}`; // Return formatted date as DD/MM/YYYY
                //     }
                //     return "N/A"; // If no date value, display "N/A"
                //   }
                // }


              ]}
              defaultPageSize={5} // Adjust based on your needs
              className="-striped -highlight"
              nextText="Suivant"
              previousText="Précédent"
              rowsText="Lignes"
              pageText="Page"
              ofText="de"
              noDataText="Aucune donnée disponible"
            />


          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
}
