import { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "@mui/material";
import Page from "../../components/Page";
import { CardBody, Row } from "reactstrap";
import ReactTable from "react-table-v6";
import { Stack, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import Cookies from 'js-cookie';
import { useLocation } from "react-router-dom";

export default function User() {
  // Get the formateur ID from the URL parameters
  const { id } = useParams();

  const [apprenants, setApprenants] = useState([]);
  const token = Cookies.get("tokenAdmin");
  const location = useLocation();
  const { formation } = location.state || {};
  const { businessName, CertifsList } = location.state || {};  // Get businessName and CertifsList from state

console.log("otheeer", CertifsList)

// const enrolledList = formation.status === "private" ? formation.enrolledByPrivee : formation.enrolledBy;
// console.log(enrolledList)


  const columns = [
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
      accessor: row => `${row.etudiant.nom} ${row.etudiant.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => (
        <span style={{ wordBreak: "break-all" }}>
          {/* <Link to={`/detailsUser/${original._id}`}> */}
            {original.etudiant.nom} {original.etudiant.prenom}
          {/* </Link> */}
        </span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => <div>{original?.etudiant.email}</div>,
    },
    // {
    //   Header: "Date Enrolled",
    //   accessor: "enrolled",
    //   id: "firstEnrollmentDate",
    //   Cell: ({ original }) => {
    //     const firstEnrollmentDateTime = original.enrolled?.length > 0 ? original.enrolled[0].dateEnrolled : null;
    //     return (
    //       <span style={{ wordBreak: "break-all" }}>
    //         {firstEnrollmentDateTime ? moment(firstEnrollmentDateTime).locale("fr").format("DD/MM/YYYY") : "No Enrollment"}
    //       </span>
    //     );
    //   },
    // },
  
    {
      Header: "Nom du certificats",
      accessor: "mesCertificats",
      Cell: ({ original }) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {original?.certiff?.name}
        </div>
      ),
    },
    {
      Header: "Score",
      accessor: "mesCertificats",
      Cell: ({ original }) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {original?.score}
        </div>
      ),
    },
  ];

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Liste des Certifiés - {businessName} {/* Display dynamic business name */}
          </Typography>
        </Stack>

        <CardBody>
          <Row>
            <ReactTable
              data={CertifsList}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
              defaultSorted={[{ id: "createdAt", desc: true }]}
            />
          </Row>
        </CardBody>
      </Container>
    </Page>
  );
}
