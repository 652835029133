// import * as React from 'react'; 
// import Cookies from 'js-cookie';
// import { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import { CardBody, Row } from "reactstrap";
// import ReactTable from "react-table-v6";
// import Page from "../../components/Page";
// import { Stack } from "@mui/material";
// import { Container } from "@mui/material";
// import { useSelector } from 'react-redux';

// export default function BasicTable() {
//   const [apprenants, setApprenants] = useState([]);
//   const token = Cookies.get("tokenAdmin");
//   const [loading, setLoading] = useState(false);
//   const { idFormation } = useParams();
  
//   // Get the current user data
//   const userConnected = useSelector((state) => state.auth.CurrentUser);
//   console.log("userConnected", userConnected);

//   useEffect(() => {
//     getApprenants();
//   }, [userConnected]); // Re-run when userConnected changes

//   const getApprenants = async () => {
//     setLoading(true);
//     try {
//       // Fetch the formation details first
//       const res = await axios.get(
//         `${process.env.REACT_APP_URL_BACKEND_DEV}/getAllApprenantsFormateur/${idFormation}`,
//         {
//           headers: { Authorization: `Bearer ${token}`, withCredentials: true },
//         }
//       );
  
//       console.log("Response Data: ", res);

//       // Check if the user is connected and has the "business" role
//       if (userConnected.role === "business") {
//         // If the user is a business, fetch apprenants from formation.enrolledByPrivee
//         const mappedBusinessEnrolled = res.data.ListEnrolledPrivee?.map((entry) => {
//           return {
//             ...entry?.etudiantId, // Ensure there's valid etudiantId
//             dateEnrolled: entry?.dateEnrolled,
//           };
//         }).filter(entry => entry); // Remove null/undefined entries
//         setApprenants(mappedBusinessEnrolled);
//       } else {
//         // Handle other types of formation
//         if (res.data.formation && res.data.formation.typeFormation?.titre === "En ligne") {
//           const mappedEnLigneEnrolled = res.data.ListEnrolled?.map((entry) => {
//             return {
//               ...entry?.etudiantId, // Ensure there's valid etudiantId
//               dateEnrolled: entry?.dateEnrolled,
//             };
//           }).filter(entry => entry); // Remove null/undefined entries
//           setApprenants(mappedEnLigneEnrolled);
//         } else if (["Webinaire", "Présentielle"].includes(res.data.formation?.typeFormation?.titre)) {
//           const mappedPresEnrolled = res.data.ListEnrolledPres?.map((entry) => {
//             return {
//               ...entry?.etudiantId, // Ensure there's valid etudiantId
//               dateEnrolled: entry?.dateEnrolled,
//             };
//           }).filter(entry => entry);
//           console.log("fffff") // Remove null/undefined entries
//           setApprenants(mappedPresEnrolled);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching data: ", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   console.log("appp", apprenants);

//   const columns = [
//     {
//       Header: "Nom et Prénom",
//       id: "nomPrenom",
//       accessor: row => `${row.nom} ${row.prenom}`,
//       filterMethod: (filter, row) =>
//         row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
//       Cell: ({ original }) => {
//         return (
//           <span style={{ wordBreak: "break-all" }}>
//             {original.nom} {original.prenom}
//           </span>
//         );
//       },
//     },
//     {
//       Header: "Email",
//       accessor: "email",
//       Cell: ({ original }) => {
//         return <div>{original?.email}</div>;
//       },
//     },
//     {
//       Header: "Date d'inscription",
//       accessor: "dateEnrolled",
//       Cell: ({ original }) => {
//         // Formatting the date if it's available
//         return <div>{original?.dateEnrolled ? new Date(original.dateEnrolled).toLocaleString() : ''}</div>;
//       },
//     },

//   ];

//   return (
//     <Page title="Dashboard | Fastuz">
//       <Container>
//         <Stack
//           direction="row"
//           alignItems="center"
//           justifyContent="space-between"
//           mb={5}
//         ></Stack>

//         <CardBody>
//           <Row>
//             <ReactTable
//               data={apprenants}
//               nextText={"Suivant"}
//               previousText={"Précedent"}
//               rowsText={"Lignes"}
//               columns={columns}
//               defaultPageSize={10}
//               filterable={true}
//               noDataText="No data is available"
//             />
//           </Row>
//         </CardBody>
//       </Container>
//     </Page>
//   );
// }

import * as React from 'react';
import Cookies from 'js-cookie';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CardBody, Row } from "reactstrap";
import ReactTable from "react-table-v6";
import Page from "../../components/Page";
import { Stack } from "@mui/material";
import { Container } from "@mui/material";
import { useSelector } from 'react-redux';

export default function BasicTable() {
  const [apprenants, setApprenants] = useState([]);
  const token = Cookies.get("tokenAdmin");
  const [loading, setLoading] = useState(false);
  const { idFormation } = useParams();

  // Get the current user data
  const userConnected = useSelector((state) => state.auth.CurrentUser);
  console.log("userConnected", userConnected);

  useEffect(() => {
    getApprenants();
  }, [userConnected]); // Re-run when userConnected changes

  const getApprenants = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND_DEV}/getAllApprenantsFormateur/${idFormation}`,
        {
          headers: { Authorization: `Bearer ${token}`, withCredentials: true },
        }
      );

      console.log("Response Data: ", res.data);

      if (userConnected.role === "business") {
        const mappedBusinessEnrolled = res.data.ListEnrolledPrivee?.map((entry) => {
          return {
            ...entry?.etudiantId,
            dateEnrolled: entry?.dateEnrolled,
            isCertified: entry?.isCertified, // Inclure le champ isCertified
          };
        }).filter((entry) => entry);
        setApprenants(mappedBusinessEnrolled);
      } else  
      {
        if (res.data.formation.status!=="private")
          {
          if (res.data.formation && res.data.formation.typeFormation?.titre === "En ligne") {
            const mappedEnLigneEnrolled = res.data.ListEnrolled?.map((entry) => {
              return {
                ...entry?.etudiantId,
                dateEnrolled: entry?.dateEnrolled,
                isCertified: entry?.isCertified, // Inclure le champ isCertified
              };
            }).filter((entry) => entry);
            setApprenants(mappedEnLigneEnrolled);
          } else if (["Webinaire", "Présentielle"].includes(res.data.formation?.typeFormation?.titre)) {
            const mappedPresEnrolled = res.data.ListEnrolledPres?.map((entry) => {
              return {
                ...entry?.etudiantId,
                dateEnrolled: entry?.dateEnrolled,
                isCertified: entry?.isCertified, // Inclure le champ isCertified
              };
            }).filter((entry) => entry);
            setApprenants(mappedPresEnrolled);
          }
        }
        else {
          const mappedBusinessEnrolled = res.data.ListEnrolledPrivee?.map((entry) => {
            return {
              ...entry?.etudiantId,
              dateEnrolled: entry?.dateEnrolled,
              isCertified: entry?.isCertified, // Inclure le champ isCertified
            };
          }).filter((entry) => entry);
          setApprenants(mappedBusinessEnrolled);
        }
       

      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("appp", apprenants);

  const columns = [
    {
      Header: "Nom et Prénom",
      id: "nomPrenom",
      accessor: row => `${row.nom} ${row.prenom}`,
      filterMethod: (filter, row) =>
        row.nomPrenom.toLowerCase().includes(filter.value.toLowerCase()),
      Cell: ({ original }) => {
        return (
          <span style={{ wordBreak: "break-all" }}>
            {original.nom} {original.prenom}
          </span>
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original }) => {
        return <div>{original?.email}</div>;
      },
    },
    {
      Header: "Date d'inscription",
      accessor: "dateEnrolled",
      Cell: ({ original }) => {
        // Formatting the date if it's available
        return <div>{original?.dateEnrolled ? new Date(original.dateEnrolled).toLocaleString() : ''}</div>;
      },
    },
    {
      Header: "Certifié",
      accessor: "isCertified",
      Cell: ({ original }) => {
        { console.log(original.isCertified) }
        // Formatting the date if it's available
        const textColor = original?.isCertified === "Yes" ? "green" : "red";

        return <div style={{ display: "flex", justifyContent: "center", color: textColor  }}>
          {original?.isCertified === "Yes" ? "Certifié" : "Non"}</div>;
      },
    },

  ];

  return (
    <Page title="Dashboard | Fastuz">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        ></Stack>

        <CardBody>
          <Row>
            <ReactTable
              data={apprenants}
              nextText={"Suivant"}
              previousText={"Précedent"}
              rowsText={"Lignes"}
              columns={columns}
              defaultPageSize={10}
              filterable={true}
              noDataText="No data is available"
            />
          </Row>
        </CardBody>
      </Container>
    </Page>
  );
}